// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    blank: `${ROOTS.DASHBOARD}/blank`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    seguros: {
      root: `${ROOTS.DASHBOARD}/seguros`,
      siniestros: `${ROOTS.DASHBOARD}/seguros/siniestros`,
      polizas: `${ROOTS.DASHBOARD}/seguros/polizas`,
      new: `${ROOTS.DASHBOARD}/seguros/polizas/new`,
      view: (id) => `${ROOTS.DASHBOARD}/seguros/polizas/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/seguros/polizas/${id}/edit`,
      renovacion: (id) => `${ROOTS.DASHBOARD}/seguros/polizas/${id}/renovacion`,
      evaluacion: (id) => `${ROOTS.DASHBOARD}/seguros/polizas/${id}/evaluacion`,
      newSiniestro: `${ROOTS.DASHBOARD}/seguros/siniestro/new`,
      viewSiniestro: (id) => `${ROOTS.DASHBOARD}/seguros/siniestro/${id}`,
      editSiniestro: (id) => `${ROOTS.DASHBOARD}/seguros/siniestro/${id}/edit`,
      repositorio: `${ROOTS.DASHBOARD}/seguros/repositorio`,
      soporte: `${ROOTS.DASHBOARD}/soporte/soporte`,
      tareas: `${ROOTS.DASHBOARD}/seguros/tareas`,
      evaluacionPoliza: `${ROOTS.DASHBOARD}/seguros/evaluacionPoliza`,
      herramientas: `${ROOTS.DASHBOARD}/seguros/herramientas`,
      dashboardRiesgos: `${ROOTS.DASHBOARD}/seguros/dashboardRiesgos`,
      newRiesgo: `${ROOTS.DASHBOARD}/seguros/riesgo/new`,
      viewRiesgo: (id) => `${ROOTS.DASHBOARD}/seguros/riesgo/${id}`,
      editRiesgo: (id) => `${ROOTS.DASHBOARD}/seguros/riesgo/${id}/edit`,
      gestionRiesgo: (id) => `${ROOTS.DASHBOARD}/seguros/riesgo/${id}/gestion`,
      estadisticas: `${ROOTS.DASHBOARD}/seguros/estadisticas`,
    },
    soporte: {
      soporte: `${ROOTS.DASHBOARD}/soporte/soporte`,
    },
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
    },
    user: {
      // root: `${ROOTS.DASHBOARD}/user`,
      // profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      // edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      new: `${ROOTS.DASHBOARD}/user/new`,
    },
  },
};
